<template>
  <div>
    <el-tabs v-model="tab">
      <el-tab-pane label="Individuals" name="individuals">
        <IndividualMenuTypes />
      </el-tab-pane>
      <el-tab-pane label="Corporates" name="corporates">
        <CorporateMenuTypes />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
export default {
  name: "Meal",
  components: {
    CorporateMenuTypes: () => import("./CorporateMenuTypes"),
    IndividualMenuTypes: () => import("./IndividualMenuTypes"),
  },
  data() {
    return {
      tab: "individuals",
    };
  },
  watch: {
    tab() {
      if (this.tab == "individuals") {
        this.$store.dispatch(actions.GET_MENU_TYPES);
      }
    },
  },
};
</script>
